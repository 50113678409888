<template>
    <a class="common-card-product"
        v-bind:href="product.url"
        v-on:click.prevent="e => { productClickHandler(e, product) }"
        v-on:mouseenter="mouseEnterHandler"
        v-on:mouseleave="mouseLeaveHandler"
        v-bind:class="{ _sale: isSale }"
        ref="root"
        >
        <div class="common-card-product__background"
            v-if="coverImage || video"
            >
            <ui-video
                v-if="video"
                v-bind:video="video.src"
                v-bind:poster="video.poster"
                v-bind:aspect-ratio="videoAspectRatio"
            />
            <common-lazy-background
                v-else
                v-bind:image="coverImage"
            />
        </div>
        <div class="common-card-product__hover"
            v-if="wasHovered && hoverImage"
            >
            <common-lazy-background v-bind:image="hoverImage" />
        </div>
        <div class="common-card-product__info">
            <div class="common-card-product__info-badges"
                v-if="badges.length > 0"
                >
                <div class="common-card-product__info-badges-item"
                    v-for="(badge, index) in badges"
                    v-bind:key="index"
                    >
                    <icon v-bind:name="'badge-' + badge" height="1em" width="auto" original />
                </div>
            </div>
            <div class="common-card-product__info-collection"
                v-if="product.collection"
                v-text="product.collection"
            />
            <div class="common-card-product__info-model"
                v-if="product.model && product.color"
                v-text="product.model"
            />
            <div class="common-card-product__info-botline">
                <div class="common-card-product__info-botline-color"
                    v-text="product.color || product.model"
                />
                <div class="common-card-product__info-botline-price">
                    <div class="common-card-product__info-botline-price-old"
                        v-if="product.price.oldText"
                        v-text="product.price.oldText"
                    />
                    <div class="common-card-product__info-botline-price-current"
                        v-text="product.price.text"
                    />
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import config from '~/config';
import utils from '~/utils';
import { productClick } from '~/utils/ecommerce';
import { ImpressionController } from '~/utils/analytics';

export default {
    name: 'common-card-product',
    props: {
        product: {
            type: Object,
        },
        isFocused: {
            type: Boolean,
            default: false,
        },
        ecommerceListData: {
            type: Object,
        },
    },
    data: () => ({
        impressionController: null,
        wasHovered: false,
        isHovered: false,
        videoAspectRatio: window.innerWidth <= 768 ? config.media.aspectRatioMobile : config.media.defaultAspectRatio,
    }),
    computed: {
        isSale() {
            return !!this.product.price.oldText;
        },
        badges() {
            const result = [];
            if (this.product.clipon_related_product?.url || this.product.clipon?.url) {
                result.push('clipon');
            }
            if (this.isSale) {
                result.push('sale-light');
            }
            return result;
        },
        video() {
            if (!this.product.video) {
                return null;
            }
            let widthToCalculateFrom = window.innerWidth / 2;
            if (window.innerWidth >= 1024) {
                widthToCalculateFrom = window.innerWidth / 4;
            } else if (window.innerWidth >= 768) {
                widthToCalculateFrom = window.innerWidth / 3;
            }
            widthToCalculateFrom = Math.ceil(widthToCalculateFrom);
            const videoSize = utils.common.getVideoResize({ targetWidth: widthToCalculateFrom });
            const src = this.product.video[videoSize];
            const poster = this.product.videoPreview ? this.product.videoPreview[videoSize] : null;
            return { src, poster };
        },
        coverImage() {
            if (this.product.images.length > 0) {
                return this.product.images[0].medium;
            }
            return null;
        },
        hoverImage() {
            if (this.product.images.length > 1) {
                const activeGenderFilter = this.$store.getters['catalog/filters/isFemaleFilterActive']
                    ? config.filters.gender.values.female.code
                    : this.$store.getters['catalog/filters/isMaleFilterActive']
                        ? config.filters.gender.values.male.code
                        : null;
                const currentCollectionGender = this.$store.getters['collections/currentCollectionGender'];
                const genderCode = activeGenderFilter || currentCollectionGender;
                if (genderCode !== null) {
                    const imageByGender = this.product.images.find((x, i) => i > 0 && x.gender === config.media.markers.genders[genderCode]);
                    if (imageByGender) {
                        return imageByGender.medium;
                    }
                    const imageWithNoGender = this.product.images.find((x, i) => i > 0 && !x.gender);
                    if (imageWithNoGender) {
                        return imageWithNoGender.medium;
                    }
                }
                return this.product.images[1].medium;
            }
            return null;
        },
    },
    methods: {
        productClickHandler(e, product) {
            productClick({ ...this.ecommerceListData, product: product.ecommerce });
            let url = product.url;
            if (this.ecommerceListData?.listId && this.ecommerceListData?.listTitle) {
                url = product.url + `?tgm_list_id=${this.ecommerceListData?.listId}&tgm_list_title=${this.ecommerceListData.listTitle}`;
            }
            if (e.metaKey || e.ctrlKey) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        },
        mouseEnterHandler() {
            this.isHovered = true;
            if (this.wasHovered) {
                return;
            }
            this.wasHovered = true;
        },
        mouseLeaveHandler() {
            this.isHovered = false;
        },
    },
    mounted() {
        const root = this.$refs.root;
        if (!root) {
            return;
        }
        if (this.isFocused) {
            root.scrollIntoView();
        }
        this.impressionController = new ImpressionController(root, { ...this.ecommerceListData, product: this.product.ecommerce });
    },
    beforeDestroy() {
        this.impressionController.destroy();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-card-product {
    position: relative;
    z-index: 1;

    display: block;
    width: 100%;
    padding-top: 122.7%;

    font-size: 1.4rem;
    line-height: 2rem;

    background-color: @color-gray-main;

    cursor: pointer;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__hover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        background-color: transparent;

        opacity: 0;

        transition: opacity @duration-fast ease-out;
    }
    &:hover & {
        &__hover {
            opacity: 1;
        }
    }
    &__info {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        padding: 20px;
        &-badges {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            margin: 0 auto auto 0;

            pointer-events: none;
            &-item {
                font-size: 2rem;
            }
        }
        &-collection {
            flex: 0 0 auto;
        }
        &-model {
            flex: 0 0 auto;
        }
        &-botline {
            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            &-color {
                flex: 1 1 auto;
                padding-right: 20px;
            }
            &-price {
                flex: 1 1 auto;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                justify-content: flex-end;

                text-align: right;
                &-old {
                    text-decoration: line-through;
                }
                &-old + &-current {
                    padding-left: 8px;
                }
            }
        }
    }
    @media @media-sm-down {
        padding-top: 80%;
    }
}
</style>
