var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-item"},[_c('section',{staticClass:"page-item__main",class:{ _loading: _vm.isLoading }},[_c('div',{staticClass:"page-item__main-gallery"},[(!_vm.isLoading && _vm.currentChild.images && _vm.currentChild.images.length > 0)?_c('common-resize-controller',{key:_vm.currentChild.id,attrs:{"components":[
                    {
                        maxWidth: 768,
                        name: 'page-item-gallery-mobile',
                        props: {
                            currentChild: _vm.currentChild,
                        },
                    },
                    {
                        minWidth: 768,
                        name: 'page-item-gallery-desktop',
                        props: {
                            currentChild: _vm.currentChild,
                        },
                    } ]}}):_vm._e()],1),(_vm.badges.length > 0)?_c('div',{staticClass:"page-item__main-badges"},_vm._l((_vm.badges),function(badge,index){return _c('div',{key:index,staticClass:"page-item__main-badges-item",style:((badge.action || badge.hint) ? {
                    pointerEvents: 'all',
                    cursor: 'help',
                } : null),on:{"click":function($event){badge.action ? _vm.performBadgeAction(badge.action) : null}}},[_c('icon',{attrs:{"name":'badge-' + badge.icon,"height":"1em","width":"auto","original":""}}),(badge.hint)?_c('div',{staticClass:"page-item__main-badges-item-hint",domProps:{"textContent":_vm._s(badge.hint)}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"page-item__main-common"},[(!_vm.isLoading)?_c('page-item-common',{attrs:{"current-child":_vm.currentChild,"item":_vm.item,"type":_vm.type,"lens-type":_vm.lensType ? _vm.lensType.value : null},on:{"switch-child":_vm.switchChild}}):_vm._e()],1),(_vm.type !== 'certificate')?_c('div',{staticClass:"page-item__main-info"},[(!_vm.isLoading)?_c('page-item-info',{attrs:{"current-child":_vm.currentChild,"item":_vm.item,"type":_vm.type,"lens-type":_vm.lensType ? _vm.lensType.value : null}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('div',{staticClass:"page-item__main-loader"},[_c('div',{staticClass:"page-item__main-loader-container"},[_c('ui-loader')],1)]):_vm._e()])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.galleryIndex !== null && !_vm.isLoading)?_c('div',{staticClass:"page-item__gallery"},[_c('common-resize-controller',{attrs:{"components":[
                    {
                        minWidth: 768,
                        name: 'page-item-gallery-full',
                    } ]}})],1):_vm._e()]),(_vm.item !== null && _vm.type !== 'certificate' && _vm.type !== 'accessory')?_c('section',{staticClass:"page-item__recommended"},[_c('page-item-recommended',{attrs:{"reference":_vm.item}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }